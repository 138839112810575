import { useCallback, useEffect, useMemo, useState } from "react";

import { buildApiClient, DiscordUser, ShouldRedirectToFlowError } from "../api";
import Button from "../components/Button";
import DiscordAccountCard from "../components/DiscordAccountCard";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { useConfig } from "../subsystem/config/state";

type ContractResult =
  | {
      tag: "uninit";
    }
  | {
      tag: "pending";
    }
  | {
      tag: "redirecting";
    }
  | {
      tag: "error";
      data: { error: Error };
    };

const CompletePage: React.FC = () => {
  const config = useConfig();
  const [result, setResult] = useState<ContractResult>({
    tag: "uninit",
  });
  const [userData, setUserData] = useState<DiscordUser>();
  const apiClient = useMemo(
    () => buildApiClient(config.apiUrl),
    [config.apiUrl]
  );

  useEffect(() => {
    const init = async () => {
      const data = await apiClient.getFlowDiscordUser();
      if (!data) return;

      setUserData(data);
    };

    void init().catch((error: Error) => {
      if (error instanceof ShouldRedirectToFlowError) {
        return setResult({ tag: "redirecting" });
      }
      setResult({
        tag: "error",
        data: {
          error,
        },
      });
    });
  }, [apiClient]);

  const handleApprove = useCallback(() => {
    setResult({ tag: "pending" });

    apiClient
      .grantFlowFinalApproval()
      .then(() => setResult({ tag: "redirecting" }))
      .catch((error: Error) => {
        if (error instanceof ShouldRedirectToFlowError) {
          return setResult({ tag: "redirecting" });
        }
        setResult({ tag: "error", data: { error } });
      });
  }, [apiClient]);

  const handleRedirectToWelcomePage = useCallback(() => {
    window.location.href = config.welcomePath;
  }, [config.welcomePath]);

  const handleDelete = useCallback(() => {
    setResult({ tag: "pending" });

    apiClient
      .resetFlow()
      .then(() => setResult({ tag: "redirecting" }))
      .catch((error: Error) => {
        if (error instanceof ShouldRedirectToFlowError) {
          return setResult({ tag: "redirecting" });
        }
        setResult({ tag: "error", data: { error } });
      });
  }, [apiClient]);

  const resultView = useMemo(() => {
    switch (result.tag) {
      case "pending":
        return <div>Processing...</div>;
      case "redirecting":
        window.location.href = config.flowUrl;
        return <div>Redirecting...</div>;
      case "error":
        return (
          <div className="flex flex-col gap-[1px]">
            <div className="text-rose-600/80">{result.data.error.message}</div>
          </div>
        );
      default:
        return null;
    }
  }, [config.flowUrl, result]);

  const resetDisabled = !userData && result.tag !== "error";
  const confirmDisabled = result.tag !== "uninit" || !userData;

  return (
    <Layout>
      <div className="flex flex-col">
        <div className="confirm-paper flex flex-col rounded-t-md p-4 pb-0">
          <div className="grid w-full grid-cols-1 flex-col gap-2 divide-y text-white">
            <DiscordAccountCard data={userData} />
            <div className="py-4 text-[10px] leading-4">
              <Section>
                Now you need to confirm that you want to link your biometric
                data to this account.
              </Section>
            </div>
          </div>
          {resultView !== null && (
            <div className="flex flex-col items-center pb-4">
              <div className="text-center text-xs">{resultView}</div>
            </div>
          )}
        </div>
        <div className="rounded-b-md bg-zinc-800">
          <div className="flex w-full justify-between gap-2 p-4">
            <Button
              disabled={resetDisabled}
              onClick={
                result.tag === "error"
                  ? handleRedirectToWelcomePage
                  : handleDelete
              }
              variant="secondary"
              label="Reset"
            />
            <Button
              disabled={confirmDisabled}
              onClick={handleApprove}
              variant="primary"
              label="Confirm"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CompletePage;
