import React from "react";

import Layout from "../components/Layout";

const PageNotFound: React.FC = () => (
  <Layout>
    <div className="paper">
      <svg
        className="mt-10 rounded-full border-4 p-1 text-rose-500"
        aria-hidden="true"
        role="img"
        width="75"
        height="75"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M18.4 4L12 10.4L5.6 4L4 5.6L10.4 12L4 18.4L5.6 20L12 13.6L18.4 20L20 18.4L13.6 12L20 5.6L18.4 4Z"
        ></path>
      </svg>
      <div className="mt-8 text-center text-base text-white">Error</div>
      <div className="text-center text-xs">
        <div>Page not found</div>
      </div>
    </div>
  </Layout>
);

export default PageNotFound;
