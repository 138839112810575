import React, { useCallback } from "react";
import { BrowserRouter } from "react-router-dom";

import Layout from "../components/Layout";
import ConfigInit from "../subsystem/config/ConfigInit";
import { ConfigGuard } from "../subsystem/config/state";
import Root from "./Root";

const ConfigInitializing = () => (
  <Layout>
    <div className="paper">Initializing the config</div>
  </Layout>
);

const ConfigError: React.FC<{ error: Error }> = ({ error }) => (
  <Layout>
    <div className="paper text-center ">
      <div>Unable to load the config</div>
      <div>{error.message}</div>
    </div>
  </Layout>
);

const Entrypoint: React.FC = () => {
  const ready = useCallback(
    () => (
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    ),
    []
  );
  return (
    <ConfigInit>
      <ConfigGuard
        uninit={ConfigInitializing}
        pending={ConfigInitializing}
        ready={ready}
        error={ConfigError}
      />
    </ConfigInit>
  );
};

export default Entrypoint;
