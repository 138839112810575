const variants = {
  primary: {
    disabled: "bg-indigo-400/10 text-gray-700",
    enabled:
      "bg-indigo-400/80 text-white hover:bg-indigo-500/80 active:bg-indigo-600/80",
  },
  secondary: {
    disabled: "bg-rose-400/10 text-gray-700",
    enabled:
      "bg-rose-400/80 text-white hover:bg-rose-500/80 active:bg-rose-600/80",
  },
};

interface ButtonProps extends React.ComponentProps<"button"> {
  variant: keyof typeof variants;
  label: string;
}
const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button
      {...props}
      className={[
        props.className,
        "rounded-[4px] w-fit px-5 py-2.5 pt-2 text-center text-sm font-bold transition-all",
        props.disabled
          ? `${variants[props.variant].disabled} cursor-not-allowed`
          : variants[props.variant].enabled,
        "btn",
      ].join(" ")}
    >
      {props.label}
    </button>
  );
};

export default Button;
