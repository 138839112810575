import { isErrorFromAlias, Zodios, ZodiosResponseByAlias } from "@zodios/core";

import { fromErrorResponse } from "./errors";
import { api } from "./schema";
import { Aliases, Api } from "./types";

type ApiHandler<Alias extends Aliases> = () => Promise<
  ZodiosResponseByAlias<Api, Alias>
>;
type ApiHandlers = {
  [Key in Aliases]: ApiHandler<Key>;
};

function apiErrorHandler(error: unknown, alias: Aliases): Error {
  if (!isErrorFromAlias(api, alias, error)) {
    if (!(error instanceof Error)) {
      throw error;
    }
    return error;
  }

  return fromErrorResponse(error.response.data);
}

export const buildApiClient = (apiUrl: string): ApiHandlers => {
  const client = new Zodios(apiUrl, api);

  return api.reduce((acc, cur) => {
    const handler: ApiHandler<typeof cur.alias> = async () => {
      try {
        if (cur.method === "get") {
          return await client[cur.alias]({
            withCredentials: true,
          });
        }

        return await client[cur.alias](undefined, {
          withCredentials: true,
        });
      } catch (error) {
        throw apiErrorHandler(error, cur.alias);
      }
    };

    return {
      ...acc,
      [cur.alias]: handler,
    };
  }, {} as ApiHandlers);
};
