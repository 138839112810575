import { PropsWithChildren, useCallback, useId, useState } from "react";

import { ReactComponent as CheckmarkIcon } from "../assets/checkmark.svg";
import { ReactComponent as ExclamationIcon } from "../assets/exclamation.svg";
import { ReactComponent as ProtectionIcon } from "../assets/protection.svg";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { useConfig } from "../subsystem/config/state";

type CheckboxProps = {
  defaultChecked: boolean;
  label?: React.ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};
const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { defaultChecked, label, onChange } = props;
  const id = useId();
  return (
    <div className="flex w-full items-start gap-2">
      <input
        id={id}
        type="checkbox"
        defaultChecked={defaultChecked}
        onChange={onChange}
        className="absolute h-4 w-4 appearance-none"
      />
      <div
        className={[
          "h-4 w-4 rounded-[3px] cursor-pointer",
          defaultChecked ? "bg-white" : "border-[#747f8d] border-[1px]",
        ].join(" ")}
      >
        <CheckmarkIcon className={defaultChecked ? "" : "hidden"} />
      </div>
      {label && (
        <label
          htmlFor={id}
          className="w-[calc(100%_-_24px)] text-[10px] leading-4"
        >
          {label}
        </label>
      )}
    </div>
  );
};

const ExclamationSection: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <div className="flex items-center">
      <div className="font-roboto mr-4 p-[2px]">
        <div className="h-fit min-w-[24px] rounded-full bg-yellow-500 p-1">
          <ExclamationIcon stroke="white" fill="white" />
        </div>
      </div>
      <div className="font-semibold leading-[20px] text-white">{children}</div>
    </div>
  );
};

const WelcomePage: React.FC = () => {
  const [agreement, setAgreement] = useState<boolean>(false);
  const [adult, setAdult] = useState<boolean>(false);

  const { flowUrl, privacyPolicy, termsOfService } = useConfig();

  const handleClick = useCallback(() => {
    window.location.href = flowUrl;
  }, [flowUrl]);

  return (
    <Layout>
      <div className="flex flex-col">
        <div className="bg-bgSecondary welcome-paper grid grid-cols-1 flex-col items-start divide-y rounded-t-md px-4 py-5 pt-4 text-gray-400 drop-shadow">
          <div className="welcome-paper-title flex flex-col pb-4">
            <div className="font-normal">You will need to</div>
            <div className="font-bold text-white">log in to Discord</div>
            <div className="font-bold text-white">
              and link your account to your biometrics
            </div>
          </div>
          <div className="flex flex-col gap-2 py-4 text-sm">
            <ExclamationSection>
              Bind your Discord account ID to your physical identity
            </ExclamationSection>
            <ExclamationSection>
              You can only do it once, and will not able to bind a second
              account
            </ExclamationSection>
            <ExclamationSection>
              You can not unbind your account
            </ExclamationSection>
          </div>
          <div className="flex w-full flex-col gap-4 pt-4">
            <Section icon={<ProtectionIcon />}>
              During a live video session we will take your biometric data,
              encrypt it and send to confidential compute servers where no one
              will have access to it.
            </Section>
            <Checkbox
              defaultChecked={agreement}
              onChange={() => setAgreement(!agreement)}
              label={
                <>
                  I have read and agree to the{" "}
                  <a
                    href={termsOfService}
                    target="_blank"
                    rel="noreferrer"
                    className="link-text"
                  >
                    Terms of service
                  </a>{" "}
                  and{" "}
                  <a
                    href={privacyPolicy}
                    target="_blank"
                    rel="noreferrer"
                    className="link-text"
                  >
                    Privacy policy
                  </a>
                  .
                </>
              }
            />
            <Checkbox
              defaultChecked={adult}
              onChange={() => setAdult(!adult)}
              label="I certify that I am 18 years of age or older and of legal adult age in my jurisdiction of residence."
            />
          </div>
        </div>
        <div className="rounded-b-md bg-zinc-800">
          <div className="flex w-full justify-end p-4">
            <Button
              disabled={!agreement || !adult}
              type="button"
              onClick={handleClick}
              variant="primary"
              label="Next"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WelcomePage;
