import { loadProduction } from "hybridconfig";

type Routes =
  | "welcomePath"
  | "donePath"
  | "errorPath"
  | "confirmPath"
  | "apiUrl"
  | "flowUrl"
  | "termsOfService"
  | "privacyPolicy";

type AllowedKeys = Routes;

const config = loadProduction<AllowedKeys>({
  remoteConfigPath: "/config.json",
  envKeyPrefix: "REACT_APP_",
}).then((config) => {
  if (config.apiUrl === undefined) {
    throw new Error("apiUrl is missing in the config");
  }

  if (config.welcomePath === undefined) {
    throw new Error("welcomePath is missing in the config");
  } else if (!config.welcomePath.startsWith("/")) {
    throw new Error('welcomePath must start with "/"');
  }

  if (config.donePath === undefined) {
    throw new Error("donePath is missing in the config");
  } else if (!config.donePath.startsWith("/")) {
    throw new Error('donePath must start with "/"');
  }

  if (config.confirmPath === undefined) {
    throw new Error("confirmPath is missing in the config");
  } else if (!config.confirmPath.startsWith("/")) {
    throw new Error('confirmPath must start with "/"');
  }

  if (config.errorPath === undefined) {
    throw new Error("errorPath is missing in the config");
  } else if (!config.errorPath.startsWith("/")) {
    throw new Error('errorPath must start with "/"');
  }

  if (config.flowUrl === undefined) {
    throw new Error("flowUrl is missing in the config");
  }

  return config;
});

export default config;
