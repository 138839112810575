import { PropsWithChildren } from "react";

type SectionProps = {
  icon?: React.ReactNode;
};
const Section: React.FC<PropsWithChildren<SectionProps>> = (props) => {
  const { icon, children } = props;

  return (
    <div className="flex w-full items-start justify-start gap-2">
      {icon && <div className="h-4 w-4">{icon}</div>}
      <div className="text-[10px] leading-4">{children}</div>
    </div>
  );
};

export default Section;
