import { ErrorResponse } from "./types";

export class ServerError extends Error {
  public errorResponse: unknown;
}

export class ShouldRedirectToFlowError extends ServerError {}

export const fromErrorResponse = (data: ErrorResponse): ServerError => {
  const { message, shouldRedirectToFlow } = data;

  const serverError = shouldRedirectToFlow
    ? new ShouldRedirectToFlowError(message)
    : new ServerError(message);

  serverError.errorResponse = data;
  return serverError;
};
