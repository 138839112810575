import { DiscordUser } from "../api";

const DefaultAvatar = () => (
  <svg
    className="h-16 w-16 text-gray-200 dark:text-gray-700"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
  </svg>
);

type DiscordAccountCardProps = {
  data?: DiscordUser;
};
const DiscordAccountCard: React.FC<DiscordAccountCardProps> = (props) => {
  const { data } = props;

  if (!data) {
    return (
      <div className="flex flex-col items-center gap-4 py-4">
        <DefaultAvatar />

        <div>
          <div className="mb-2 h-2.5 w-10 rounded-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="mt-2 h-2 w-10 rounded-full bg-gray-200 dark:bg-gray-700"></div>
        </div>
        <div className="mt-4 mb-[2px] h-3 w-52 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-4 py-4">
      {data.avatar ? (
        <div>
          <img
            src={`https://cdn.discordapp.com/avatars/${data.id}/${data.avatar}`}
            className="h-16 w-16 rounded-full"
          />
        </div>
      ) : (
        <DefaultAvatar />
      )}

      <div>
        {data.global_name ? (
          <div className="text-lg leading-4">
            {data.global_name}
            {Number(data.discriminator) === 0 ? "" : `#${data.discriminator}`}
          </div>
        ) : null}
        <div className="text-sm">{data.username}</div>
      </div>
      <div className="text-sm text-gray-400">
        User ID: <span className="text-white">{data.id}</span>
      </div>
    </div>
  );
};

export default DiscordAccountCard;
