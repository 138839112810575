import { makeApi, makeErrors } from "@zodios/core";
import { z } from "zod";

const errorShapes = {
  display: z.object({
    message: z.string(),
    shouldRedirectToFlow: z.literal(false),
  }),
  maybeRedirect: z.object({
    message: z.string(),
    shouldRedirectToFlow: z.boolean(),
  }),
} as const;

export const apiErrors = <T>(body: z.ZodType<T>) =>
  makeErrors([
    {
      status: "default",
      description: "Default error shape",
      schema: body,
    },
  ]);

export const api = makeApi([
  {
    method: "get",
    path: "/flow/discord-user",
    requestFormat: "json",
    errors: apiErrors(errorShapes.maybeRedirect),
    response: z.object({
      id: z.string(),
      username: z.string(),
      discriminator: z.string(),
      global_name: z.optional(z.nullable(z.string())),
      avatar: z.optional(z.nullable(z.string())),
    }),
    alias: "getFlowDiscordUser",
    description: "Get discord user data",
  },
  {
    method: "post",
    path: "/flow/final-approval",
    requestFormat: "text",
    parameters: [],
    errors: apiErrors(errorShapes.display),
    response: z.undefined(),
    alias: "grantFlowFinalApproval",
    description:
      "Send a linking confirmation from the user, which means the user agrees to have their biomap linked to their Discord account.",
  },
  {
    method: "delete",
    path: "/flow",
    requestFormat: "json",
    parameters: [],
    errors: apiErrors(errorShapes.maybeRedirect),
    response: z.undefined({}),
    alias: "resetFlow",
    description: "Delete session on server-side",
  },
]);
