import Layout from "../components/Layout";

const CompletePage: React.FC = () => {
  return (
    <Layout>
      <div className="paper">
        <svg
          className="text-green mt-10 rounded-full border-4 p-1"
          aria-hidden="true"
          role="img"
          width="75"
          height="75"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.99991 16.17L4.82991 12L3.40991 13.41L8.99991 19L20.9999 7.00003L19.5899 5.59003L8.99991 16.17Z"
          ></path>
        </svg>
        <div className="mt-8 text-center text-base text-white">Done</div>
        <div className="text-center text-xs">
          <div>You can come back to Discord</div>
          <div>and activate the linked role.</div>
        </div>
      </div>
    </Layout>
  );
};

export default CompletePage;
