import React, { useEffect, useRef } from "react";

const R = 25;
let width: number;
let height: number;
const units: Unit[] = [];
const unitCount = 20;
let hue = 0;
const mouseXY: { x: number; y: number } = { x: -1, y: -1 };
let ctx: CanvasRenderingContext2D;

function createUnits() {
  const intervalId: NodeJS.Timer = setInterval(() => {
    if (units.length >= unitCount) {
      return clearInterval(intervalId);
    }
    units.push(new Unit());
  }, 200);
}

function drawScene(ctx: CanvasRenderingContext2D) {
  for (let i = 0; i < units.length; i++) {
    units[i].update();
    units[i].draw(ctx);
  }
}

function getRandomInt(min: number, max: number) {
  return Math.round(Math.random() * (max - min)) + min;
}

class Unit {
  constructed: boolean;
  x = 0;
  y = 0;
  sx = 0;
  sy = 0;
  angle = 0;
  size = 1;
  radian = 0;
  speed = 2;
  maxDistance = 30;
  time = 0;
  ttl = 360;
  hue = 0;

  constructor() {
    this.reset();
    this.constructed = true;
  }

  setupXY() {
    if (mouseXY.x === -1 && mouseXY.y === -1) {
      this.x = Math.round(width / 2);
      this.y = Math.round(height / 2);
    } else {
      this.x = mouseXY.x;
      this.y = mouseXY.y;
    }
    this.sx = this.x;
    this.sy = this.y;
  }

  reset() {
    this.setupXY();
    this.angle = 60 * getRandomInt(0, 5);
    this.size = 1;
    this.radian = (Math.PI / 180) * (this.angle + 90);
    this.speed = 2;
    this.maxDistance = 30;
    this.time = 0;
    this.ttl = getRandomInt(180, 300);
    this.hue = hue;
    hue += 0.5;
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.save();
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
    ctx.fillStyle = `hsl(${this.hue}, 100%, 50%)`;
    ctx.shadowColor = `hsl(${this.hue}, 100%, 50%)`;
    ctx.shadowBlur = 5;
    ctx.fill();
    ctx.closePath();
    ctx.restore();
  }

  update() {
    const dx = this.sx - this.x;
    const dy = this.sy - this.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance >= R) {
      if (getRandomInt(0, 1)) {
        this.angle += 60;
      } else {
        this.angle -= 60;
      }

      this.radian = (Math.PI / 180) * (this.angle + 90);
      this.sx = this.x;
      this.sy = this.y;
    }

    this.x += this.speed * Math.sin(this.radian);
    this.y += this.speed * Math.cos(this.radian);

    if (
      this.time >= this.ttl ||
      this.x < 0 ||
      this.x > width ||
      this.y < 0 ||
      this.y > height
    ) {
      this.reset();
    }

    this.time++;
  }
}

const Background: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let running = true;
    function animationLoop() {
      if (!ctx || !running) return;

      ctx.fillStyle = "rgba(0, 0, 0, .05)";
      ctx.fillRect(0, 0, width, height);

      drawScene(ctx);
      requestAnimationFrame(animationLoop);
    }

    function init() {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext("2d");
      if (!context) return;
      ctx = context;

      hue = 0;

      resizeReset();
      createUnits();
      animationLoop();
    }

    function resizeReset() {
      const canvas = canvasRef.current;
      if (!canvas) return;
      if (!ctx) return;

      width = canvas.width = window.innerWidth;
      height = canvas.height = window.innerHeight;

      ctx.fillStyle = "#222";
      ctx.fillRect(0, 0, width, height);
    }

    init();
    const handleMouseMove = (event: MouseEvent) => {
      const H = Math.sqrt(3) * R;
      const hexY = Math.floor((event.clientY - H / 2) / H);
      const hexX = Math.floor((event.clientX - R) / (2 * R));
      const y = hexX % 2 === 0 ? hexY * H + H / 2 : hexY * H;
      const x = hexX * (2 * R) + R / 2;
      mouseXY.x = x - R / 2;
      mouseXY.y = y + H / 2;
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", resizeReset);

    return () => {
      running = false;
      window.removeEventListener("resize", resizeReset);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        zIndex: -10,
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000",
      }}
    />
  );
};

export default Background;
