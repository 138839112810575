import { PropsWithChildren } from "react";

import Background from "./Background";

const Layout: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <div className="page">
      <Background />
      {children}
    </div>
  );
};

export default Layout;
