import qs from "qs";
import React, { useEffect, useMemo, useState } from "react";

import Layout from "../components/Layout";

const extendedContentComponents: Record<
  string,
  { title: React.ReactNode; message: React.ReactNode }
> = {
  "uniqueness check failed": {
    title: "",
    message: (
      <div key="message" className="flex flex-col gap-2">
        <div className="text-sm">Uniqueness check failed</div>
        <div className="flex flex-col gap-2">
          <div className="text-sm text-white">Possible reasons</div>
          <ol className="flex list-decimal flex-col gap-2 text-left">
            <li>
              You already bound your biometrics to another Discord account{" "}
            </li>
            <li>You are using another face for this Discord account now </li>
          </ol>
        </div>
      </div>
    ),
  },
};

const ErrorPage: React.FC = () => {
  const [data, setData] = useState<qs.ParsedQs>({});

  useEffect(() => {
    setData(qs.parse(location.search.replace("?", "")));
  }, []);

  const content = useMemo(() => {
    const message = data.message
      ? String(data.message)
      : "Something went wrong";
    const extendData = extendedContentComponents[message];
    if (!extendData) {
      return (
        <>
          <div className="mt-8 text-center text-base text-white">Error</div>
          <div className="pt-2 text-center text-xs">{message}</div>
        </>
      );
    }

    const { title: extendedTitleComponent, message: extendedMessageComponent } =
      extendData;

    return (
      <>
        <div className="mt-8 text-center text-base text-white">
          {extendedTitleComponent === null ? "Error" : extendedTitleComponent}
        </div>
        <div className="pt-2 text-center text-xs">
          {extendedMessageComponent ?? message}
        </div>
      </>
    );
  }, [data.message]);

  return (
    <Layout>
      <div className="paper">
        <svg
          className="mt-10 rounded-full border-4 p-1 text-rose-500"
          aria-hidden="true"
          role="img"
          width="75"
          height="75"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M18.4 4L12 10.4L5.6 4L4 5.6L10.4 12L4 18.4L5.6 20L12 13.6L18.4 20L20 18.4L13.6 12L20 5.6L18.4 4Z"
          ></path>
        </svg>
        {content}
      </div>
    </Layout>
  );
};

export default ErrorPage;
