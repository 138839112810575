import React from "react";
import { Route, Routes } from "react-router";

import ConfirmPage from "../pages/ConfirmPage";
import DonePage from "../pages/DonePage";
import ErrorPage from "../pages/ErrorPage";
import PageNotFound from "../pages/PageNotFound";
import WelcomePage from "../pages/WelcomePage";
import { useConfig } from "../subsystem/config/state";

const Root: React.FC = () => {
  const { welcomePath, errorPath, donePath, confirmPath } = useConfig();
  return (
    <Routes>
      <Route path={confirmPath} element={<ConfirmPage />} />
      <Route path={donePath} element={<DonePage />} />
      <Route path={welcomePath} element={<WelcomePage />} />
      <Route path={errorPath} element={<ErrorPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Root;
