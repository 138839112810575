import { PropsWithChildren, useEffect, useState } from "react";

import config from "../../config";
import { ConfigContext, State } from "./state";

const ConfigInit: React.FC<PropsWithChildren<Record<never, never>>> = ({
  children,
}) => {
  const [configState, setConfigState] = useState<State>({
    tag: "uninit",
    data: {},
  });

  useEffect(() => {
    setConfigState({ tag: "pending", data: {} });
    config
      .then((data) => {
        return setConfigState({ tag: "ready", data });
      })
      .catch((error: Error) => {
        setConfigState({ tag: "error", data: { error } });
      });
  }, []);

  return (
    <ConfigContext.Provider value={configState}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigInit;
